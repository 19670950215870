
import {
  transactionsMapActions,
  transactionsMapGetters
} from "@/store/modules/transactions";
import Vue from "vue";
import { tableColumnsListTransactions } from "./TransactionsTable";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";

interface ITransactionData {
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  transactionId: string;
  filters: any[];
  loadingText: string;
}
interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}
export default Vue.extend({
  name: "transactions",
  components: {
    NoResultsFound
  },
  data(): ITransactionData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableColumns: tableColumnsListTransactions,
      loading: false,
      transactionId: "",
      filters: [
        {
          field: "status",
          title: "Status",
          options: [
            { label: "Succeeded", value: "succeeded" },
            { label: "Failed", value: "failed" }
          ]
        },
        {
          field: "onFiserv",
          title: "On Fiserv",
          options: [
            { label: "Yes", value: true },
            { label: "No", value: false }
          ]
        },
        {
          field: "transactionDate",
          title: "Transaction Date",
          options: [],
          isDate: true
        },
        {
          field: "paymentCategory",
          title: "Transaction Type",
          options: [
            { label: "Credit Card", value: "card" },
            { label: "E-check", value: "check" }
          ]
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...transactionsMapActions(["getTransactions"]),
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading transactions. Please wait...";
        this.$emit("scrollTop");
        await this.getTransactions({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "transaction-activities":
          this.$router.push(`/admin/transactions/activities`).catch(() => {});
          break;
        default:
          return null;
      }
    }
  },
  computed: {
    ...transactionsMapGetters([
      "transactions",
      "getTotalRecords",
      "getTransactionsLinks"
    ]),
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "transaction-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    }
  }
});
