// import * as selectOptions from "@/helpers/selectOptions";

import { formatDate } from "@/helpers/dateProcessor";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import { formatCurrencyFloat } from "@/plugins/filters";

export const tableColumnsListTransactions = {
  onFiserv: {
    formatDataKey: "On Fiserv",
    formatDataValue: (arg: boolean) => (arg ? "Yes" : "No"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0,
    colWidth: 120
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) =>
      arg === "succeeded"
        ? "Success"
        : arg === "failed"
        ? capitalizeFirstLetter(arg)
        : "",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: false,
    position: 1,
    colWidth: 100
  },
  transactionPolicyNumber: {
    formatDataKey: "Policy",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: false,
    position: 3,
    colWidth: 100
  },
  policyPrefix: {
    formatDataKey: "Prefix",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: false,
    position: 2,
    colWidth: 90
  },
  customerName: {
    formatDataKey: "Customer Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4
  },
  paymentCategory: {
    formatDataKey: "Payment Type",
    formatDataValue: (arg: string) =>
      arg === "card" ? "Credit card" : arg === "check" ? "E-check" : "",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 5,
    colWidth: 140
  },
  paymentAmount: {
    formatDataKey: "Payment Amount",
    formatDataValue: (arg: number) => (arg ? formatCurrencyFloat(arg) : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 7,
    colWidth: 160
  },
  totalAmount: {
    formatDataKey: "Total Payment Amount",
    formatDataValue: (arg: number) => (arg ? formatCurrencyFloat(arg) : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    colWidth: 200,
    allowSort: true,
    position: 8
  },
  transactionDate: {
    formatDataKey: "Date",
    formatDataValue: (arg: string) => (arg ? formatDate(arg) : arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 120,
    position: 9
  }
};
